import React, {Component} from 'react';
import ReactDOM from 'react-dom';

export default class PlanningEmployeeSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }
    }

    toggle() {
        this.setState({
            open: !this.state.open
        }) 
    }

    render() {
        const classNames = ["dropdown"]
        if (this.state.open) {
            classNames.push('is-open')
        }

        return <div className={classNames.join(" ")}>
            <div className="dropdown-current" onClick={() => this.toggle()}>Aktuell</div>
            <div className="dropdown-list">
                <ol>
                    <li>Bla</li>
                </ol>
            </div>
        </div>
    }
}